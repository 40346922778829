<template>
  <div id="UpdateProfile" class="page-content-inner">
    <h3 class="mb-4">{{ $t('profile.account_settings') }}</h3>
    <div uk-grid>
      <div class="uk-width-1-4@m uk-flex-last@m">
        <div class="uk-card-default rounded text-center p-4">
          <div class="uk-position-relative my-4">
            <div class="user-profile-photo  m-auto">
              <img id="profile-image-holder" :src="profileUser.photo_link" alt="">
            </div>
            <div class="uk-position-center">
              <div uk-form-custom>
                <input ref="file" type="file" accept="image" @change="onChangeFile">
                <span class="uk-link icon-feather-camera icon-medium text-white"> </span>
              </div>
            </div>
          </div>
          <button class="btn btn-default m-auto mb-3" @click="updateProfilePhoto"><i class="uil-image-upload"></i>
            {{ $t('profile.update_photo') }}
          </button>
        </div>
      </div>
      <div class="uk-width-expand@m">
        <div class="uk-card-default rounded">
          <div class="p-3">
            <h5 class="mb-0"> {{ $t('profile.update_personal_information') }} </h5>
            <div v-if="successMessage" class="uk-alert-success" uk-alert>
              <a class="uk-alert-close" uk-close></a>
              <p style="color:green;">
                <i class="uil-exclamation-triangle"></i> {{ successMessage }}
              </p>
            </div>
            <div v-if="errors" class="uk-alert-danger" uk-alert>
              <a class="uk-alert-close" uk-close></a>
              <p style="color:red;">
                <i class="uil-exclamation-triangle"></i> {{ errors }}
              </p>
            </div>
            <div v-for="error in errorMessages" v-if="errorMessages" class="uk-alert-danger" uk-alert>
              <a class="uk-alert-close" uk-close></a>
              <p style="color:red;">
                <i class="uil-exclamation-triangle"></i> {{ error }}
              </p>
            </div>
          </div>
          <hr class="m-0">
          <form @submit.prevent="onSubmit()">
            <div class="uk-child-width-1-2@s uk-grid-small p-4" uk-grid>
              <div>
                <h5 class="uk-text-bold mb-2"> {{ $t('profile.name') }} </h5>
                <input v-model="profileUser.name" class="uk-input" type="text">
              </div>
              <div>
                <h5 class="uk-text-bold mb-2"> {{ $t('profile.surname') }} </h5>
                <input v-model="profileUser.surname" class="uk-input" type="text">
              </div>
              <div>
                <h5 class="uk-text-bold mb-2"> {{ $t('profile.dob') }} </h5>
                <input v-model="profileUser.dob" :formatter="format" class="uk-input" type="date">
              </div>
              <div>
                <h5 class="uk-text-bold mb-2"> {{ $t('profile.email') }} </h5>
                <input v-model="profileUser.email" class="uk-input" disabled type="text">
              </div>
              <div>
                <h5 class="uk-text-bold mb-2"> {{ $t('profile.phone') }} </h5>
                <vue-tel-input v-model="maskPhone" :inputOptions="options" type="text"></vue-tel-input>
              </div>
            </div>
            <hr>
            <div class="uk-child-width-1-2@s uk-grid-small p-4" uk-grid>
              <div>
                <h5 class="uk-text-bold mb-2"> {{ $t('profile.login_password') }} </h5>
                <input v-model="password" class="uk-input mb-1" type="password" autocomplete="chrome-off">
                <ol class="bg-white W-100 pt-1 pl-2 mb-1">
                  <li v-if="passwordValidation.hasUpperLetter" class="uk-text-small m-0" style="color:grey;">
                    <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
      $t('errors.password_must_have_upper_latter') }}
                  </li>
                  <li v-if="passwordValidation.hasLowerLetter" class="uk-text-small m-0" style="color:grey;">
                    <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
      $t('errors.password_must_have_lower_latter') }}
                  </li>
                  <li v-if="passwordValidation.hasNumber" class="uk-text-small m-0" style="color:grey;">
                    <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
      $t('errors.password_must_have_number') }}
                  </li>
                  <li v-if="passwordValidation.hasAtLeast8Letters" class="uk-text-small m-0" style="color:grey;">
                    <i aria-hidden="true" class="fa fa-check d-inline-flex mr-1"></i>{{
      $t('errors.password_must_more_than_7') }}
                  </li>
                  <li v-if="!passwordValidation.hasUpperLetter" class="uk-text-small m-0" style="color:red;">
                    <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{
      $t('errors.password_must_have_upper_latter') }}
                  </li>
                  <li v-if="!passwordValidation.hasLowerLetter" class="uk-text-small m-0" style="color:red;">
                    <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{
      $t('errors.password_must_have_lower_latter') }}
                  </li>
                  <li v-if="!passwordValidation.hasNumber" class="uk-text-small m-0" style="color:red;">
                    <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{ $t('errors.password_must_have_number')
                    }}
                  </li>
                  <li v-if="!passwordValidation.hasAtLeast8Letters" class="uk-text-small m-0" style="color:red;">
                    <i class="fa fa-dot-circle mr-1" style="font-size:9px"></i>{{ $t('errors.password_must_more_than_7')
                    }}
                  </li>
                </ol>
              </div>
              <div>
                <h5 class="uk-text-bold mb-2"> {{ $t('profile.login_password_confirm') }} </h5>
                <input v-model="password_confirmation" class="uk-input" type="password">
              </div>

              <div v-if="company.is_allowed_login_type">
                <h5 class="uk-text-bold mb-2">{{ $t('profile.login_type') }}</h5>
                <select name="login_type" v-model="profileUser.login_type_id">
                  <option v-for="item in login_types" :value="item.id">{{ item.name }}</option>
                </select>
              </div>

              <div v-if="company.is_allowed_authentication_type">
                <h5 class="uk-text-bold mb-2">{{ $t('profile.authentication_type') }}</h5>
                <select name="authentication_type" v-model="profileUser.authentication_type_id">
                  <option v-for="item in authentication_types" :value="item.id" :key="item.id">{{ item.name }}</option>
                </select>
              </div>

            </div>
          </form>
          <div class="uk-flex uk-flex-right p-4">
            <button class="btn btn-link mr-2" @click="$router.go(-1)">{{ $t('general.cancel') }}</button>
            <button class="btn btn-default grey" @click="onSubmit()">{{ $t('general.save') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module, {
  CURRENT_ITEM,
  ERROR,
  GET_USER,
  SET_ERROR,
  UPDATE_USER,
  UPDATE_USER_PHOTO,
  MODULE_NAME
} from "@/core/services/store/user.module";
import moment from "moment";
import store from "@/core/services";
import { TheMask } from 'vue-the-mask';
import $ from 'jquery';
import SecureLS from "secure-ls";
import axios from "axios";

const ls = new SecureLS({ encodingType: 'aes', isCompression: true });

export default {
  name: "UpdateProfile",
  components: {
    TheMask
  },
  data() {
    return {
      maskPhone: '',
      apiURL: 'api/user/',
      successMessage: '',
      password: null,
      password_confirmation: null,
      user_photo: null,
      profileImage: null,
      errorMessages: [],
      errorMessage: null,
      company: {},
      options: {
        showDialCode: true,
        placeholder: '5XXXXXXXXX',
        styleClasses: ['uk-input', 'border-0', 'w-100', 'h-0', 'form-input', 'uk-form-input', 'p-0', 'm-0']
      },
      login_types: [],
      authentication_types: [],
    };
  },
  computed: {
    passwordValidation() {
      let validation = {
        'hasLowerLetter': false,
        'hasUpperLetter': false,
        'hasNumber': false,
        'hasAtLeast8Letters': false,
      };
      if (this.password) {
        let lowerCaseLetters = /[a-z]/g;
        let upperCaseLetters = /[A-Z]/g;
        let numbers = /[0-9]/g;

        if (this.password.match(lowerCaseLetters)) {
          validation.hasLowerLetter = true;
        }
        if (this.password.match(upperCaseLetters)) {
          validation.hasUpperLetter = true;
        }
        if (this.password.match(numbers)) {
          validation.hasNumber = true;

        }
        if (this.password.length > 8) {
          validation.hasAtLeast8Letters = true;
        }
      }

      return validation
    },
    errors: {
      get() {
        return store.getters[MODULE_NAME + '/' + ERROR]
      },
      set(value) {
      }
    },
    profileUser: {
      get() {
        let user = store.getters[MODULE_NAME + '/' + CURRENT_ITEM]
        user.dob = user.dob ? moment(user.dob).format('YYYY-MM-DD') : null

        if (user.phone && user.phone.length === 10) {
          user.phone = "0" + user.phone;
        }

        return user
      },
      set(value) {
      }
    }
  },
  methods: {
    getAuthenticationTypes() {
      let types = []
      axios.get(`api/user/authentication-types`, {
        headers: this.apiHeaders
      }).then((result) => {
        result.data.forEach(data => {
          types.push({
            id: data.id,
            name: data.name
          })
        });
      })
      this.authentication_types = types
    },
    getLoginTypes() {
      let types = []
      axios.get(`api/user/login-types`, {
        headers: this.apiHeaders
      }).then((result) => {
        result.data.forEach(data => {
          types.push({
            id: data.id,
            name: data.name
          })
        });
      })
      this.login_types = types
    },
    getCompany() {
      let self = this;
      axios.get(`api/company/self`, {
        headers: this.apiHeaders
      }).then((result) => {
        self.company = result.data
      })
    },
    onChangeFile() {
      let value = this.$refs.file.files[0];
      this.profileImage = value;
      let blob = URL.createObjectURL(value);
      let imageHolder = $('#profile-image-holder');
      imageHolder.attr('src', blob);
    },
    format(value) {
      return moment(value).format('YYYY-MM-DD')
    },
    onSubmit() {
      this.$store.commit(MODULE_NAME + '/' + SET_ERROR, null)

      // let phoneNumber = this.profileUser.phone;
      let country_phone_code = this.maskPhone.substring(0, this.maskPhone.indexOf(' ')).substring(1)
      this.maskPhone = this.maskPhone.substring(this.maskPhone.indexOf(' ') + 1).replaceAll(' ', '');
      // phoneNumber = phoneNumber.replace(/ /g, '').substring(1);
      let form = {
        name: this.profileUser.name,
        surname: this.profileUser.surname,
        dob: this.profileUser.dob,
        phone: this.maskPhone,
        login_type_id: this.profileUser.login_type_id,
        authentication_type_id: this.profileUser.authentication_type_id,
        country_phone_code: country_phone_code
      }
      this.errorMessages = [];
      this.successMessage = null;

      if (this.profileUser.name.length === 0) {
        this.errorMessages.push(
          this.$t('errors.user_name_required'),
        );
        return false
      }
      if (this.profileUser.surname.length === 0) {
        this.errorMessages.push(
          this.$t('errors.user_surname_required'),
        );
        return false
      }
      if (this.profileUser.email.length === 0) {
        this.errorMessages.push(
          this.$t('errors.user_email_required'),
        );
        return false
      }
      if (this.password) {
        let lowerCaseLetters = /[a-z]/g;
        let upperCaseLetters = /[A-Z]/g;
        let numbers = /[0-9]/g;
        if (!this.password.match(lowerCaseLetters)) {
          this.errorMessages.push(
            this.$t('errors.password_must_have_lower_latter'),
          );
        }
        if (!this.password.match(upperCaseLetters)) {
          this.errorMessages.push(
            this.$t('errors.password_must_have_upper_latter'),
          );
        }
        if (!this.password.match(numbers)) {
          this.errorMessages.push(
            this.$t('errors.password_must_have_number'),
          );
        }
        if (this.password.length < 7) {
          this.errorMessages.push(
            this.$t('errors.password_must_more_than_7'),
          );
        }
      }
      if (this.password && this.password_confirmation) {
        form.password = this.password;
        form.password_confirmation = this.password_confirmation;
      }
      this.$store.dispatch(MODULE_NAME + '/' + UPDATE_USER, {
        url: this.apiURL + this.profileUser.id,
        contents: form,
      }).then(() => {
        setTimeout(() => {
          if (!this.errors) {
            this.successMessage = this.$t('profile.your_information_updated')
            let user_data = this.authUser
            user_data.fullname = form.name + ' ' + form.surname
            user_data.name = form.name
            user_data.surname = form.surname

            window.localStorage.removeItem('user');
            let tempUser = {};
            Object.assign(tempUser, user_data);
            ls.set('user', JSON.stringify(tempUser));
            location.reload()
          }
        }, 500)
      })
    },
    updateProfilePhoto() {
      this.$store.commit(MODULE_NAME + '/' + SET_ERROR, null)
      let form = new FormData();
      form.append('photo', this.profileImage);
      this.$store.dispatch(MODULE_NAME + '/' + UPDATE_USER_PHOTO, {
        url: this.apiURL + this.profileUser.id + '/photo',
        contents: form,
      }).then((response) => {
        if (!this.errors) {
          this.successMessage = this.$t('profile.your_information_updated')
          let user_data = this.authUser
          if (this.profileImage) {
            user_data.photo = this.profileUser.photo_link
          }
          window.localStorage.removeItem('user');
          let tempUser = {};
          Object.assign(tempUser, user_data);
          ls.set('user', JSON.stringify(tempUser));
          location.reload()
        }
      })
    }
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule)
      }
    }

    registerStoreModule(MODULE_NAME, module)
  },
  mounted() {
    this.$store.dispatch(MODULE_NAME + '/' + GET_USER, {
      url: this.apiURL + 'self',
      acceptPromise: true,
    })
    this.getAuthenticationTypes()
    this.getLoginTypes()
    this.getCompany()
  },
}
</script>

<style scoped></style>
